/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:

	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com

-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, code, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, form, legend, table, caption, tbody, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, details, summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

main, article, aside, figure, footer, header, nav, section, details, summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements:
   http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

/* Responsive images and other embedded objects */
/* if you don't have full control over `img` tags (if you have to overcome attributes), consider adding height: auto */
img,
object {
  max-width: 100%;
}

/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted.
	you'll have to restore the bullets within content,
	which is fine because they're probably customized anyway */
ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
  display: block;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: bottom;
}

 input, textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td, td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */

input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
img {
  -ms-interpolation-mode: bicubic;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

html,
body {
  font-family: "Titillium Web", --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.35;
}

.grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  height: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.section {
  min-height: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

@media screen and (min-width: 640px) {
  .section:not(:first-of-type) {
    -ms-flex-preferred-size: calc(100% / 2);
        flex-basis: calc(100% / 2);
  }
}

@media screen and (min-width: 1024px) {
  .section:first-of-type, .section:not(:first-of-type) {
    -ms-flex-preferred-size: calc(100% / 3);
        flex-basis: calc(100% / 3);
  }
}

@media screen and (min-width: 640px) {
  .section:last-of-type {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.section:last-of-type .section__image {
  padding: 64px 48px;
  background-color: #aac6fc;
}

.section__text {
  padding: 40px 32px;
  overflow: hidden;
  position: relative;
  z-index: 3;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.section__text h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
}

.section__text p {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.section__text a {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.54);
}

.section__text a:hover {
  color: #e26d5a;
  border-bottom: 1px solid #e26d5a;
}

.section:first-of-type {
  background-color: #e26d5a;
  color: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 40px;
}

.section:first-of-type h1 {
  font-size: 64px;
  letter-spacing: 4px;
  margin-top: auto;
  font-weight: 600;
}

.section:first-of-type p {
  font-size: 24px;
}

.section:first-of-type nav {
  margin-top: auto;
}

.section:first-of-type nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.section:first-of-type nav img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  opacity: 0.6;
}

.section:first-of-type nav img:hover {
  opacity: 1;
}

.section:first-of-type nav img.icon--gitlab {
  width: 40px;
  height: 40px;
  margin-left: -8px;
  margin-right: 8px;
}

.photo {
  position: relative;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  background-image: url(photo.39869d2fd8f9d508227a.jpg);
  background-size: cover;
  background-position: center;
}

.photo img {
  opacity: 0;
  visibility: hidden;
}

.photo:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@supports (mix-blend-mode: multiply) {
  .photo:hover {
    background-color: magenta;
    background-blend-mode: screen;
  }
  .photo:hover::before, .photo:hover::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    mix-blend-mode: multiply;
    background-blend-mode: screen;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  }
  .photo:hover::before {
    background-color: yellow;
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
  .photo:hover::after {
    background-color: cyan;
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}

